import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { card_col, card_row, service_card, services_section } from './CCCAreas.module.scss';
function CCCAreas() {
    const [showVM, setShowVM] = useState(false);
    const [showDB, setShowDB] = useState(false);
    const [showSA, setShowSA] = useState(false);
    const [showSB, setShowSB] = useState(false);
    const [showLI, setShowLI] = useState(false);

    const VMClose = () => setShowVM(false);
    const VMShow = () => setShowVM(true);
    const DBClose = () => setShowDB(false);
    const DBShow = () => setShowDB(true);
    const SAClose = () => setShowSA(false);
    const SAShow = () => setShowSA(true);
    const SBClose = () => setShowSB(false);
    const SBShow = () => setShowSB(true);
    const LIClose = () => setShowLI(false);
    const LIShow = () => setShowLI(true);

    return (
        <div className={services_section} data-testid="cccareas-section">
            <div className="services-container" data-testid="cccareas-container">
                <div className="px-5">
                    <div className={`row ${card_row}`}>
                        <div className={`col-xl-4 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <Card data-testid="ccc-card-1" className={`${service_card} p-2 h-100`}>
                                <a onClick={VMShow}>
                                    <Card.Title className="center">Virtual Machines</Card.Title>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/ccc/card-vms.png"
                                        alt="managed IT in the cloud"
                                    />

                                    <Card.Body></Card.Body>
                                </a>
                            </Card>
                        </div>
                        <div className={`col-xl-4 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <a onClick={DBShow}>
                                <Card data-testid="ccc-card-2" className={`${service_card} p-2 h-100`}>
                                    <Card.Title className="center">Databases</Card.Title>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/ccc/card-databases.png"
                                        alt="devops and cloud"
                                    />

                                    <Card.Body></Card.Body>
                                </Card>
                            </a>
                        </div>
                        <div className={`col-xl-4 col-lg-6 col-md-6 mb-4 clear-fix ${card_col}`}>
                            <a onClick={SAShow}>
                                <Card data-testid="ccc-card-3" className={`${service_card} p-2 h-100`}>
                                    <Card.Title className="center">Storage Accounts</Card.Title>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/ccc/card-storage.png"
                                        alt="developing software"
                                    />
                                    <Card.Body></Card.Body>
                                </Card>
                            </a>
                        </div>
                    </div>

                    {/* row 2 */}
                    <div className={`row ${card_row}`}>
                        <div className={`col-xl-4 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <a onClick={SBShow}>
                                <Card data-testid="ccc-card-4" className={`${service_card} p-2 h-100`}>
                                    <Card.Title className="center">Subscriptions</Card.Title>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/ccc/card-subscriptions.png"
                                        alt="website design"
                                    />
                                    <Card.Body></Card.Body>
                                </Card>
                            </a>
                        </div>
                        <div className={`col-xl-4 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <Card data-testid="ccc-card-5" className={`${service_card} p-2 h-100`}>
                                <Card.Title className="center">Licenses</Card.Title>
                                <a onClick={LIShow}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/ccc/card-licenses.png"
                                        alt="managed IT in the cloud"
                                    />

                                    <Card.Body></Card.Body>
                                </a>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showVM} onHide={VMClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Virtual Machines</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Virtual machines (VM&apos;s) are a crucial component of cloud computing that enable
                        organizations to run applications and services in the cloud. However, if they&apos;re not
                        optimized correctly, virtual machines can lead to unnecessary expenses that add up quickly.
                    </p>
                    <h4>Some Things We Check</h4>
                    <ul>
                        <li>Checking for the right amount of CPU, memory, and storage capacity</li>
                        <li>Removing underutilized VM&apos;s</li>
                        <li>Automating start/stop schedules</li>
                        <li>Consolidating multiple virtual machines</li>
                        <li>Monitoring usage and adjusting resource allocation</li>
                    </ul>
                </Modal.Body>
            </Modal>

            <Modal show={showDB} onHide={DBClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Databases</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Databases are a critical component of many applications, serving as the foundation for storing
                        and managing data. We&apos;ll analyze database usage and optimize indexing and querying to
                        improve performance and reduce cost.
                    </p>
                    <h4>Some Things We Check</h4>
                    <ul>
                        <li>Audit Databases</li>
                        <li>Right Size Databases</li>
                        <li>Convert from VM to managed service Databases</li>
                        <li>Licensing Review</li>
                    </ul>
                </Modal.Body>
            </Modal>

            {/* Storage Accounts */}
            <Modal show={showSA} onHide={SAClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Storage Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Azure Blob Storage is a cloud-based object storage solution that allows users to store
                        unstructured data such as text, images, and videos. Blob Storage is designed for high
                        availability, scalability, and durability.
                    </p>
                    <h4>Some Things We Check</h4>
                    <ul>
                        <li>Removing unused or orphaned storage resources</li>
                        <li>Enabling data tiering and archiving </li>
                        <li>Configuring lifecycle policies</li>
                        <li>Assessing workload requirements </li>
                        <li>Monitoring storage usage and adjusting resource allocation</li>
                    </ul>
                </Modal.Body>
            </Modal>

            {/* subscriptions */}

            <Modal show={showSB} onHide={SBClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Subscriptions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        We can identify opportunities to optimize cloud subscriptions to achieve cost savings. By
                        analyzing subscription usage and identifying under-used subscriptions, we can eliminate
                        unnecessary costs and choose the subscription types that are most cost-effective .
                    </p>
                    <h4>Some Things We Check</h4>
                    <ul>
                        <li>Audit Subscription costs</li>
                        <li>Check for better subscription types</li>
                        <li>Transition resources between Subscriptions</li>
                    </ul>
                </Modal.Body>
            </Modal>

            {/* Licenses */}
            <Modal show={showLI} onHide={LIClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Licenses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        SquareHook understands licensing models and cam evaluate licensing usage to eliminate
                        unnecessary costs. We&apos;ll help you choose the most cost-effective options based on your
                        requirements while taking advantage of available discounts.
                    </p>
                    <h4>Some Things We Check</h4>
                    <ul>
                        <li>VM licensing review</li>
                        <li>SQL Licensing Review</li>
                        <li>Licensing Audit</li>
                        <li>Ways to reduce cost of Licenses</li>
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default CCCAreas;
