import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CCCAreas from '../compontents/CCCAreas/CCCAreas';
import Layout from '../compontents/Layout/Layout';

type CCCPageProps = {
    location: any;
};

const CCCPage = ({ location }: CCCPageProps) => {
    useEffect(() => {
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');
        }
    }, []);

    return (
        <Layout location={location}>
            <Helmet>
                <title>Cloud Cost Control</title>
            </Helmet>
            {/* header */}
            <div className="row mainText m-5" data-testid="ccc-container">
                <div className="col-md-6 center">
                    <img className="img-fluid" src="../../images/ccc/ccc-icon.png"></img>
                </div>
                <div className="col-md-6">
                    <h1>FinOps Cloud Cost Control</h1>
                    <p>
                        Get Full Control of Your Azure costs and{' '}
                        <span className="emphasis">increase your cloud ROI</span>.
                    </p>
                    <p>
                        Save 30%&ndash;50% on your cloud costs{' '}
                        <span className="emphasis">without sacrificing performance.</span>
                    </p>

                    <p>
                        Contact us now to get a <span className="emphasis">free FinOps analysis</span> and savings
                        report!
                    </p>
                    <a className="button-primary" href="/contact-us">
                        Contact us
                    </a>
                </div>
            </div>
            {/* end header */}
            {/* Stakes */}
            <div className="container">
                <h2 className="callout mx-auto">
                    Businesses Using Azure Cloud Services Are Overpaying By At Least 30%
                </h2>
                <h3 className="center primary">
                    And if you think that excludes you&mdash;well, you&apos;re in for a painful revelation.
                </h3>

                <div className="container960">
                    <p>
                        It&apos;s not because you&apos;re using too much cloud. Many companies are overpaying for Azure
                        simply because <strong>they don&apos;t know how to find cost savings.</strong> They assume that
                        the price Microsoft asks of them is the only price and don&apos;t think to look for savings, and
                        they end up paying significantly more than they need to.
                    </p>
                    <div className="row">
                        <div className="col-md-4">
                            <img
                                className="img-fluid"
                                src="../../images/ccc/detailed-breakdown.png"
                                alt="magnifying glass with a bill"></img>
                        </div>
                        <div className="col-md-8">
                            <p>Most companies do not have a clear picture of their cloud usage and fees.</p>
                            <p>
                                Your company will get the bill and pay it. But there&apos;s no visual breakdown of how
                                that bill came to be.
                            </p>
                            <p>What cloud services are still needed and what is surplus waste?</p>
                        </div>
                    </div>
                </div>
                <p className="mainText">
                    But that&apos;s only part of the problem! Everyone ends up overpaying for cloud because of these
                    problems they don&apos;t even know they have:
                </p>
                <div className="row">
                    <div className="col-md-4">
                        <h5 className="center mb-0">Unaware of Savings</h5>
                        <img className="img-fluid" src="../../images/ccc/savings.png" alt="piggy bank with money"></img>
                        <p>
                            They don&apos;t know the most effective pricing models, deal structures, and hidden
                            discounts, which could dramatically lower their fees.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h5 className="center mb-0">No FinOps Management</h5>
                        <img
                            className="img-fluid"
                            src="../../images/ccc/management.png"
                            alt="person managing costs"></img>
                        <p>
                            Their cloud developers are focused on their projects, but no one is responsible (or
                            experienced enough) to optimize for cost reductions.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h5 className="center mb-0">Lack of Cloud Cost Monitoring</h5>
                        <img className="img-fluid" src="../../images/ccc/monitoring.png" alt="bar graph"></img>
                        <p>
                            Without real-time cloud monitoring, irregularities and spikes in charges go unnoticed. As
                            the cloud grows, the waste grows with it.
                        </p>
                    </div>
                </div>
            </div>
            {/* end stakes */}
            {/* Value */}
            <div className="container mt-5">
                <h3 className="center headerbold">
                    Take Charge of Your Cloud Finances with <span className="primary">Cloud Cost Control</span>
                </h3>
                <div className="row">
                    <div className="col-lg-8 col-md-10 mx-auto">
                        <p>
                            At SquareHook, we understand that FinOps and waste reduction are essential to the success of
                            your business. That&apos;s why we offer Cloud Cost Control, with the mission to help
                            businesses save 30%&mdash;50% on their cloud expenses without losing any cloud capabilities.
                        </p>
                    </div>
                </div>
                <h3 className="center mt-5">Get started with FinOps using this 3 step guide:</h3>
                <div className="prose">
                    <h4>Step One: Free Analysis and Report</h4>
                    <p>
                        We start with an analysis of your Azure setup. Our team of cloud experts will give you a free
                        detailed report on your cloud usage and spending, and provide recommendations that can help you
                        start saving immediately.
                    </p>
                    <p>
                        Click here to see a{' '}
                        <a href="/documents/sample-analysis-report.pdf" target="blank">
                            sample of the report you&apos;ll get.
                            <img src="/images/ccc/ccc-report.png" />
                        </a>
                    </p>
                    <h4>Step Two: Real-Time Monitoring</h4>
                    <p>
                        We provide real-time monitoring of your cloud usage and spending, so we can identify any areas
                        of waste, and help you make informed decisions about how to keep your cloud service optimized
                        while minimizing costs.
                    </p>
                    <h4>Step Three: Cost Savings and Optimization</h4>
                    <p>
                        We actively manage your cloud expenses, so you don&apos;t have to worry about it. We&apos;ll
                        collaborate with you to make sure your cloud spend is aligned with your business needs, so you
                        can focus on what really matters: growing your business.
                    </p>
                </div>
            </div>
            {/* end value */}
            {/* guide */}
            <div className="container mt-5">
                <h3>How to Check for Cloud Savings</h3>
                <p> When looking to maximize savings on your cloud, there are five main areas you should check.</p>
                <CCCAreas />
                <p>
                    SquareHook&apos;s Cloud Cost Control is a comprehensive service that checks all these key areas for
                    efficiency. With our personalized approach, we&apos;ll be your guide to significant savings on your
                    Azure spend. Don&apos;t settle for overpaying for your cloud; take control and boost its
                    effectiveness.
                </p>
            </div>
            {/* end guide */}

            {/* cta short */}
            <div className="bgsection my-3">
                <div className="container my-5">
                    <h2 className="center">Ready to start saving on your cloud?</h2>
                    <p className="mainText center">
                        It&apos;s time to take charge of your cloud usage and save 30%&ndash;50%.
                    </p>
                    <p className="center mainText" style={{ fontWeight: 'bold' }}>
                        Schedule a free assessment of your cloud usage and costs. <br />
                    </p>
                    <p className="center mainText">
                        <a
                            className="button-primary"
                            href="https://calendly.com/clearfinops/30min?month"
                            target="blank">
                            Book A Chat
                        </a>
                    </p>

                    <p>
                        Every dollar wasted on unused cloud services or inefficient pricing models is a dollar that
                        could have been invested in your company&apos;s growth or used to improve your product
                        offerings. Overpaying for Azure means you&apos;re missing out on opportunities to hire new
                        talent, develop new features, and outpace your competitors.
                    </p>
                    <p>
                        Don&apos;t let a lack of cloud management be the downfall of your business. Take action now and
                        start saving on your Azure fees with SquareHook&apos;s cloud cost control.
                    </p>

                    <p className="center">
                        Click here to see a{' '}
                        <a href="/documents/sample-analysis-report.pdf" target="blank">
                            sample of the report you&apos;ll get.
                            <img src="/images/ccc/ccc-report.png" />
                        </a>
                    </p>
                </div>
            </div>
            {/* end cta short */}
            {/* explanitory */}
            <div className="container">
                <div className="container960">
                    <h3>Maximize Your Cloud ROI and Efficiency with Cloud Cost Control</h3>
                    <p>
                        At SquareHook, we know you want to be a smart, efficient business that&apos;s always making the
                        most of its resources. In order to achieve that, you need to be able to{' '}
                        <span className="emphasis">fully manage your Azure budget.</span> The problem is that most
                        companies are overpaying for their cloud services and don&apos;t know where to start when it
                        comes to cost optimization.
                    </p>
                    <p>
                        This can make you feel like you&apos;re{' '}
                        <span className="emphasis">
                            wasting money and resources that could be better spent elsewhere.
                        </span>{' '}
                        We believe that it&apos;s not right for any business to be overpaying for their cloud services
                        when they could be getting the same or better results for a fraction of the cost.
                    </p>
                    <p>
                        Cloud management can be overwhelming, and that&apos;s why we&apos;ve created{' '}
                        <span className="emphasis">Cloud Cost Control</span> service. We&apos;ll work with you to
                        analyze your cloud setup and usage, identify cost-saving opportunities, and make the necessary
                        changes to ensure you&apos;re getting the most out of your Azure platform.
                    </p>
                    <p>
                        So if you want to stop wasting money on your cloud services and start running a lean, efficient
                        business, contact us today and let us guide you towards better cloud cost management.
                    </p>
                </div>
            </div>

            {/* Call To Action */}

            <div className="container my-5 center">
                <div className="callout mx-auto py-1 mb-3">
                    <h2>So What Are You Waiting For?</h2>
                </div>

                <h3>Stop overpaying for Azure. Save thousands of dollars a month without any effort!</h3>
                <p>
                    Don&apos;t wait until your next cloud bill arrives to take action.
                    <span className="emphasis"> Contact us now for a free analysis and report</span> to see how much you
                    could be saving! <br />
                </p>
                <p className="center mt-3 mainText">
                    <a className="button-primary" href="https://calendly.com/clearfinops/30min?month" target="blank">
                        Book A Chat
                    </a>
                </p>
            </div>
        </Layout>
    );
};

export default CCCPage;
